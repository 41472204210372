<template>
  <div class="i-wrap">
    <div class="item" v-for="(item, index) in stages" :key="index">
      <div class="item-main">
        <div class="info">
          <div class="info-img">
            <img class="img-main" v-if="item.approvalStatus == 'UNREVIEWED'"
                 src="@/assets/icon/declare/submit.png">
            <img class="img-main"
                 v-if="item.approvalStatus == 'APPROVALPENDING' || item.approvalStatus == 'APPROVED'"
                 src="@/assets/icon/declare/approval.png">
            <img class="img-main"
                 v-if="item.approvalStatus == 'REVIEWFAILED' ||item.approvalStatus == 'APPROVALFAILED'"
                 src="@/assets/icon/declare/faild.png">
            <img class="bottom-after"
                 v-if="item.approvalStatus == 'REVIEWFAILED' ||item.approvalStatus == 'APPROVALFAILED'"
                 src="@/assets/icon/declare/reject.png">
            <img class="bottom-after"
                 v-if="item.approvalStatus == 'APPROVALPENDING' ||item.approvalStatus == 'APPROVED'"
                 src="@/assets/icon/declare/success.png">
          </div>
          <div class="info-message">
            <div class="approval-user">{{ item.operator }}</div>
            <div class="approval-time">{{ item.created }}</div>
          </div>
        </div>
        <div class="stage-status" :class="item.approvalStatus">
          <span>{{ fmtStatus(item.approvalStatus) }}</span>
          <span v-if="item[`${item.approvalStatus}-size`] > 1">(第{{ item[`${item.approvalStatus}-size`] }}次)</span>
        </div>
      </div>
      <div class="item-bottom">
        <div class="item-line" v-if="index < stages.length - 1 || item.reason"></div>
        <div class="reason" v-if="item.reason">{{ item.reason }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDeclareByDraftTableId,
  approvalLogList
} from '@/service/declare/index'

export default {
  name: 'ApprovalPopover',
  props: ['id'],
  data () {
    return {
      // 流程列表
      stages: [],
      approvalStatusList: []
    }
  },
  created () {
    this.getDicts('dec_approval_status').then(res => {
      this.approvalStatusList = res.data
      this.handleInitData()
    })
  },
  methods: {
    handleInitData () {
      approvalLogList({ declareId: this.id }).then(res => {
        let statusComputed = {}
        res.data.forEach(i => {
          if (!statusComputed[i.approvalStatus] && statusComputed[i.approvalStatus] != 0) statusComputed[i.approvalStatus] = 1
          else statusComputed[i.approvalStatus]++
          i[`${i.approvalStatus}-size`] = statusComputed[i.approvalStatus]
        })
        this.stages = res.data
      })
    },
    fmtStatus (val) {
      let label = this.getDictLabel(this.approvalStatusList, val)
      switch (val) {
        case 'UNREVIEWED' :
          return '发起申请'
        case 'APPROVALPENDING' :
          return '复核通过'
        default:
          return label
      }
    }
  }
}
</script>

<style scoped lang="scss">
.i-wrap {
  padding: 12px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
}

.item {

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  .item-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .info-img {
        position: relative;

        .img-main {
          width: 30px;
          height: 30px;
        }

        .bottom-after {
          width: 14px;
          height: 14px;
          margin-top: 18px;
          margin-left: -8px;
          position: absolute;
        }
      }

      .info-message {
        color: #666666;
        margin-left: 16px;

        .approval-time {
          padding-top: 2px;
          font-size: 11px;
        }
      }
    }
  }

  .item-bottom {
    display: flex;
    flex-direction: row;

    .item-line {
      margin: 4px 15px 6px;
      min-height: 30px;
      width: 0;
      border: 0.5px solid #3D94FF;
    }

    .reason {
      border-radius: 8px;
      padding: 8px;
      color: #666666;
      background-color: #F4F4F4;
    }
  }
}

.stage-status {
  color: #09BB07;
}

.REVIEWFAILED, .APPROVALFAILED {
  color: #FF2600;
}
</style>
